import React,{ useState, useEffect, Fragment }  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route,Link,NavLink, Switch } from 'react-router-dom';
import Sidebar from './Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';

        function Home() {
            const [cookies] = useCookies(['myToken']);
            const [category] = useCookies(['myCategory']);
            const [superuser] = useCookies(['mySuperuser']);
            const [data,setData]=useState([])
            return (
            <div className="App" id="outer-container" >
                   <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
                    <div id="page-wrap">
                        <h1 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>Welcome to GCONCRITE</h1>
                    </div>
            </div>
        );
    };
  
  export default Home;